import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Help & Feedback`}</h1>
    <h2>{`Slack channels`}</h2>
    <p>{`The Caesars UI team maintains a Slack channel. Chat with the members of the design system team if you find bugs, request features or need support.`}</p>
    <p><a parentName="p" {...{
        "href": "https://join.slack.com/share/enQtMzgyODMxNDQyMTc5Ni05ZjQ3OTc3YzA1NDhmOTZhOTZjOTkxMjEzMTIwYzc2MTY5ZDcyMGNkZmU1MDYwMTdjODBkNTI5ODg3OTVkZmNm"
      }}>{`Start a discussion`}</a></p>
    {/* ## Office Hours 
     We organize weekly design system “office hours” where you can show up with your design system questions, support requests, feature ideas, and to discuss about possible bugs. Office hours happen every XX, XX-XX (EST). Sign up for a slot to make an appointment.
     <button>Sign up</button> */}
    {/* ## Monthly meetups
     We organize monthly gatherings for people to stay up to date on Caesars UI. The aim is to walk through any new and/or upcoming features in the design system and address any questions you might have.
     <button>Subscribe to calendar</button> */}
    {/* ## Email
     Messages sent to caesars-ui@caesars.com will be addressed by the team as quickly as possible. */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      